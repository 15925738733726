<template>
  <div id="rules">
    <PagesPath :pathTitle1="'روند ارسال کالا'" theme="1" :pathTo1="'sending-product'"></PagesPath>

    <div
      class="container bg-light boxShadow20 p-5 mt-3 mb-5"
      style="border-radius: 20px;"
    >
      <div>
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-12 column">
            <div>
              <p style="text-align:right"><strong>روند ارسال کالا</strong></p>

              <ul>
                <li style="text-align: right;">
                  ثبت نام در سایت و ورود به حساب کاربری
                </li>
                <li style="text-align: right;">
                  انتخاب تنوع مورد نیاز از بین تنوع های موجود در هر کالا
                </li>
                <li style="text-align: right;">
                  افزودن کالا به سبد خرید
                </li>
                <li style="text-align: right;">
                  ادامه فرایند خرید در سبد خرید
                </li>
                <li style="text-align: right;">
                  افزودن آدرس و انتخاب نوع ارسال کالا
                </li>
                <li style="text-align: right;">
                  در گام آخر انتخاب درگاه پرداخت و ورود به درگاه و تکمیل فرایند
                  پرداخت
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PagesPath from "@/parts/Front/components/PagesPath";

export default {
  name: "sending-product",
  components: {
    PagesPath,
  },
  computed: {
    contact() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  metaInfo() {
    return {
      title: this.contact
        ? this.contact.settings.customer_setting.shipping_meta_title 
        : "",
        meta: [{ name: "robots", content: "index,follow" },
        {
          name: "description",
          content: this.contact
            ? this.contact.settings.customer_setting.shipping_meta_description
            : "",
        },
      
      ],
      link: [
        {
          rel: "canonical",
          href:  this.canonical?? "https://sajadcameron.ir"+this.$route.fullPath,
        },
      ],
    };
  },
};
</script>
