<template>
  <div v-if="homeData">
    <PagesPath :pathTitle1="'درباره ما'" theme="1" :pathTo1="'about-us'"></PagesPath>
    <section
      id="aboutcontainer"
      class="container bg-light boxShadow20 p-5 mt-3" style="border-radius: 20px;"
    >
      <h4 class="text-color-444 my-4 font-weight-bold text-center">
        درباره ی {{ homeData.settings.customer_setting?homeData.settings.customer_setting.title:"" }}
      </h4>

      <hr />
      <div id="aboutUsContent">
        {{ homeData.settings.customer_setting?homeData.settings.customer_setting.about_us_site:"" }}
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>

<script>
import PagesPath from "@/parts/Front/components/PagesPath";
export default {
  name: "AboutUs",
  components: {
    PagesPath,
  },
  data() {
    return {
      options: [
        {
          title: "تضمین اصالت",
        },
        {
          title: "قیمت مناسب",
        },
        {
          title: "کیفیت خوب",
        },
      ],
    };
  },
  mounted() {
    let aboutUsImg = document.querySelectorAll(".about-us-why-img")[0];
    aboutUsImg.style.height = aboutUsImg.offsetWidth * 1.2 + "px";
    window.addEventListener("resize", function() {
      let aboutUsImg = document.querySelectorAll(".about-us-why-img")[0];
      aboutUsImg.style.height = aboutUsImg.offsetWidth * 1.2 + "px";
    });
  },
  computed: {
    homeData() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  metaInfo() {
    return {
      title: this.homeData
        ? this.homeData.settings.customer_setting.about_us_meta_title
        : "",
      meta: [
        {
          name: "description",
          content: this.homeData
            ? this.homeData.settings.customer_setting.about_us_meta_description
            : "",
        },
        {
          property: "og:title",
          content: this.homeData
            ? this.homeData.settings.customer_setting.about_us_meta_title
            : "",
        },
        { name: "robots", content: "index,follow" },
      ],
      link: [
        {
          rel: "canonical",
          href: "https://sajadcameron.ir"+this.$route.fullPath,
        },
      ],
    };
  },
};
</script>

<style scoped>
.aboutus__images {
  object-fit: contain;
  /* border-radius: 5px; */
  width: 40%;
  /* box-shadow: 0px 0px 10px 4px #ccc; */
}
hr {
  margin-top: 35px !important;
  margin-bottom: 40px !important;
  height: 0.1px;
  width: 50%;
  margin: 0 auto;
  background-color: #000;
}
@media (max-width: 767px) {
  .aboutus__images {
    width: 80%;
    margin: 0 auto;
  }
}
#aboutcontainer {
  min-height: 70vh;
}
div#aboutUsContent {
  text-align: justify;
}
</style>
