<template>
  <div id="rules">
    <PagesPath :pathTitle1="'قوانین ومقررات'" theme="1" :pathTo1="'rules'"></PagesPath>

    <div class="container bg-light boxShadow20 p-5 mt-3 mb-5" style="border-radius: 20px;">
      <div>
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-12 column">
            <div>
              <p style="text-align:right">&nbsp;</p>

              <p style="text-align:right">&nbsp;</p>

              <p style="text-align:right"><strong>قوانین ومقررات</strong></p>

              <ul>
                <li style="text-align: right;">
                  محصولات شامل تخفیف، در زمره محصولات فروش قطعی قرار دارند و
                  غیرقابل بازگشت خواهند بود.
                </li>
                <li style="text-align: right;">
                  در صورت تعویض محصولات باید در بسته‌بندی اصلی بازگشت داده شوند.
                </li>
                <li style="text-align: right;">
                  نباید هیچگونه خراشی بر روی محصولات وجود داشته باشد و لازم است
                  در حالت اولیه بازگردانده شوند.
                </li>
                <li style="text-align: right;">
                  چنانچه عدم تطابق به وجود آمده بدون استفاده و باز نمودن بسته
                  بندی کالا قابل مشاهده باشد (مانند رنگ، سایز و مشخصات درج شده
                  در سایت)، باید کالا در شرایط اولیه به همان صورتی که کالا به
                  مشتری تحویل شده &nbsp;باشد&nbsp;و از آن استفاده ای نشده باشد
                </li>
                <li style="text-align: right;">
                  &nbsp;&nbsp;چنانچه کالای پوشاک خریداری شده دارای مارک، دکمه،
                  بند، اتیکت و سایر متعلقات باشد، به هیچ عنوان نباید از آن جدا
                  شده و به همان صورت&nbsp;باید برگردانده شود
                </li>
                <li style="text-align: right;">
                  محصولات نخ پنبه به هیچ عنوان مرجوع یا تعویض ندارند و تعویض فقط
                  در صورت مغایرت کالای تحویل گرفته شده با مشخصات مندرج در سایت
                  امکان پذیر است&nbsp;
                </li>
                <li style="text-align: right;">
                  محصولات فروخته شده مرجوع ندارد مگر در مواردی مانند زدگی و
                  مغایرت با کالای معرفی&nbsp;&nbsp;شده در وبسایت
                </li>
                <li style="text-align: right;">
                  اجناس وی آی پی به هیچ عنوان قابلیت مرجوع و تعویض ندارند&nbsp;
                </li>
                <li style="text-align: right;">
                  کالاهایی که اتمام موجودی شده به هیچ عنوان قابلیت تعویض و مرجوع
                  ندارند
                </li>
                <li style="text-align: right;">
                  اجناسی مانند کلاه و اکسسوری ها امکان تعویض و مرجوع
                  ندارند&nbsp;
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PagesPath from "@/parts/Front/components/PagesPath";

export default {
  name: "rules",
  components: {
    PagesPath,
  },
  computed: {
    contact() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  metaInfo() {
    return {
      title: this.contact
        ? this.contact.settings.customer_setting.rules_meta_title 
        : "",
        meta: [{ name: "robots", content: "index,follow" },
        {
          name: "description",
          content: this.contact
            ? this.contact.settings.customer_setting.rules_meta_description
            : "",
        },
      
      ],
      link: [
        {
          rel: "canonical",
          href:  this.canonical?? "https://sajadcameron.ir"+this.$route.fullPath,
        },
      ],
    };
  },
};
</script>