<template>
  <div>
    <PagesPath v-if="!status" :pathTitle1="content.title" :pathTo1="$route.fullPath"  theme="1"></PagesPath>
    <div class="page p-3 pt-4 bg-white box-shaddow20 container mt-4 mb-5">
      <b-skeleton-img v-if="status" />
      <template v-else>
        <div v-html="content.text"></div>
      </template>
    </div>
  </div>
</template>
<script>
import {BSkeletonImg} from 'bootstrap-vue'
import PagesPath from "@/parts/Front/components/PagesPath";
export default {
  name: 'pages',
  components: {
    PagesPath,
    BSkeletonImg
  },
    // ارسال درخواست برای دریافت محتویات صفحه قبل از ساخت کامپوننت
    beforeCreate(){
        this.$store.dispatch("front/getPageFromServer",this.$route.params.page)
    },
    computed:{
        content(){
            return this.$store.getters["front/getPageContent"];
        },
        status(){
            return this.$store.getters["front/getPageContentStatus"];
        }
    },

}
</script>
<style>
.page img {
  max-width: 100%;
}
</style>
