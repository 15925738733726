<template>
  <div>
    <PagesPath :pathTitle1="'شیوه های پرداخت '" theme="1" :pathTo1="'payment-method'"></PagesPath>
    <div class="container bg-light boxShadow20 p-5 mt-3 mb-5" style="border-radius: 20px;">
      <div>
        <div class="row">
          <div class="col-lg-12 col-sm-12 col-12 column">
            <div>
              <h4>
                <strong>شیوه های پرداخت اینترنتی پوشاک سجاد کمرون</strong>
              </h4>

              <p></p>

              <p><strong>پرداخت اینترنتی</strong></p>

              <p>
                کاربران پوشاک سجاد کمرون می‌توانند در هنگام ثبت سفارش، از طریق درگاه
                اینترنتی، هزینه سفارش خود را به
                صورت<strong>آنلاین</strong>پرداخت کنند. پرداخت موفق هزینه به
                منزله ثبت قطعی این پرداخت برای سفارش است و پس از آن پیامکی مبنی
                بر ثبت موفق سفارش، برای کاربر ارسال می‌شود و نیازی به اطلاع‌
                دادن پرداخت سفارش نیست و سفارش به صورت خودکار وارد مراحل
                آماده‌سازی و ارسال می شود.
              </p>

              <p>
                لازم به ذکر است که پرداخت اینترنتی باعث ایجاد الویت و تسریع در
                پردازش سفارش کاربران می‌شود.
              </p>

              <p></p>

              <p>
                هنگام ثبت سفارش میتوانید<strong>پرداخت اینترنتی</strong>را
                انتخاب نمایید و بعد از وارد شدن به درگاه ،اطلاعات پرداخت خود را
                وارد کنید.
              </p>

              <p></p>

              <p>برای پرداخت اینترنتی شما نیاز دارید به ;</p>

              <p></p>

              <p>–<strong>شماره کارت</strong>(شماره 16 رقمی روی عابر بانک )</p>

              <p>
                –<strong>رمز دوم</strong><strong>کارت </strong>(برای پرداخت
                اینترنتی لازم است قبلا رمز دوم (رمز خرید اینترنتی) کارت بانکی
                خود را فعال کرده باشید.<br />
                برای فعال‌کردن رمز خرید اینترنتی، می توانید به دستگاه خودپرداز
                بانک صادرکننده کارت خود مراجعه، کارت خود را وارد کرده و بخش
                عملیات رمز را انتخاب کنید. سپس، در بخش رمز دوم یا رمز اینترنتی،
                رمز خود را انتخاب کنید.<br />
                –<strong>CVV2</strong>( یک کد ۳ یا ۴ رقمی است که پشت یا روی
                کارت‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌های
                بانکی درج می‌شود و
                به‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌عنوان یک
                کد امنیتی در
                پرداخت‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌‌های
                اینترنتی کاربرد دارد )<br />
                –<strong>تاریخ انقضاء</strong><strong>(</strong>تاریخ انقضا
                کارت‌ بانکی روی آن حک شده است. اگر روی کارت شما تاریخ انقضا وجود
                ندارد، می توانید از عدد ۱۲ به جای ماه و از ۹۹ به جای سال انقضای
                کارت استفاده کنید.)
              </p>

              <p></p>

              <p>
                <strong>اطلاعات و شرایط لازم جهت مرجوعی وجه به مشتری</strong>
              </p>

              <p></p>

              <p>
                – مشتری میبایست شماره کارت بانکی که حساب آن به نام صاحب پروفایل
                میباشد را در حساب کاربری پوشاک سجاد کمرون خود ثبت نماید. (در خصوص
                افراد زیر 18 سال فاقد کارت بانکی، شماره کارت یکی از والدین ایشان
                با اولویت پدر، کفایت میکند.)<br />
                – در صورت لزوم عودت وجه به حساب مشتری، اصل بر این است که مبلغ به
                شماره کارت اعلام شده توسط مشتری عودت خواهد شد. مشتری با درج
                شماره کارت در حساب کاربری خود میپذیرد که نسبت به انجام تراکنش
                های مالی در خصوص عودت وجه خرید های انجام شده از پوشاک سجاد کمرون،
                رضایت داشته و با معرفی کارت در حساب کاربری مسئولیت های قانونی در
                این خصوص را میپذیرد.<br />
                – در صورتی که در هر خرید واریز اولیه از کارت دیگری صورت گرفته
                باشد و مغایرتی بین حساب مبداء که پرداخت اولیه از آن انجام شده با
                حساب اعلامی توسط مشتری در حساب کاربری کشف شود، پوشاک سجاد کمرون مطابق
                با ضوابط و مقررات قانونی، مبلغ را به حساب مبدا عودت خواهد داد.
              </p>

              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PagesPath from "@/parts/Front/components/PagesPath";
export default {
  name: "payment-method",
  components: {
    PagesPath,
  },
  computed: {
    contact() {
      return this.$store.getters["front/getHomeData"];
    },
  },
  metaInfo() {
    return {
      title: this.contact
        ? this.contact.settings.customer_setting.payment_meta_title 
        : "",
        meta: [{ name: "robots", content: "index,follow" },
        {
          name: "description",
          content: this.contact
            ? this.contact.settings.customer_setting.payment_meta_description
            : "",
        },
      
      ],
      link: [
        {
          rel: "canonical",
          href:  this.canonical?? "https://sajadcameron.ir"+this.$route.fullPath,
        },
      ],
    };
  },
};
</script>